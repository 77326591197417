import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useStore } from 'react-redux';
import { Button, Row, Col, Dropdown, Form } from 'react-bootstrap'
import { ShowList } from '../usersSlice'
import Spinner from '../../../spinner/Spinner';
import './createUser.scss';
import { show } from '../../Alerter/alerterSlice';
import instanceOfAxios from '../../../../app/axios/axios';
import { getUserToUpdate } from '../usersSlice'
import { FaRegSave } from 'react-icons/fa';

function CreateOrUpdateUser(props) {
    const tradRole = { tenant: 'Locataire', owner: 'Propriétaire', admin: 'Administrateur', ['super-admin']: 'Super Administrateur', trainee: 'Stagiaire / Alternant' }
    const dispatch = useDispatch();
    const [params, setParams] = useState({ is_society: false });
    const [dropDownValue, setDropDownValue] = useState('Choisir un rôle');
    const [errors, setErrors] = useState([]);
    const state = useStore().getState();
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [resetPasswordVisibility, setResetPasswordVisibility] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const role = state.header.role;

    const pays = [
        "X",
        "Afghanistan",
        "Albanie",
        "Algérie",
        "Afrique du sud",
        "Andorre",
        "Angola",
        "Anguilla",
        "Antarctique",
        "Antigua-et-barbuda",
        "Argentine",
        "Arménie",
        "Aruba",
        "Australie",
        "Autriche",
        "Azerbaïdjan",
        "Bahamas",
        "Bahreïn",
        "Bangladesh",
        "Barbade",
        "Biélorussie",
        "Belgique",
        "Belize",
        "Bénin",
        "Bermudes",
        "Bhoutan",
        "Bolivie",
        "Bosnie-herzégovine",
        "Botswana",
        "Brésil",
        "Territoire britannique de l'océan indien",
        "Brunéi darussalam",
        "Bulgarie",
        "Burkina faso",
        "Burundi",
        "Cabo Verde",
        "Cambodge",
        "Cameroun",
        "Canada",
        "République centrafricaine",
        "Tchad",
        "Chili",
        "Chine",
        "Colombie",
        "Comores",
        "République du congo",
        "République démocratique du congo",
        "Costa rica",
        "Côte d'ivoire",
        "Croatie",
        "Cuba",
        "Curaçao",
        "Chypre",
        "République tchèque",
        "Danemark",
        "Djibouti",
        "Dominique",
        "République dominicaine",
        "Équateur",
        "Espagne",
        "Égypte",
        "El salvador",
        "Guinée équatoriale",
        "Érythrée",
        "Estonie",
        "Eswatini",
        "Éthiopie",
        "Fidji",
        "Finlande",
        "France",
        "Guyane française",
        "Polynésie française",
        "Terres australes françaises",
        "Gabon",
        "Gambie",
        "Géorgie",
        "Allemagne",
        "Ghana",
        "Gibraltar",
        "Grèce",
        "Groenland",
        "Grenade",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernesey",
        "Guinée",
        "Guinée-bissau",
        "Guyana",
        "Haïti",
        "Saint-Siège",
        "Honduras",
        "Hong Kong",
        "Hongrie",
        "Islande",
        "Inde",
        "Indonésie",
        "Iran",
        "Irak",
        "Irlande",
        "Île de man",
        "Île bouvet",
        "Îles heard et mcdonald",
        "Îles cook",
        "Îles malouines",
        "Îles féroé",
        "Île christmas",
        "Îles cocos (keeling)",
        "Îles caïmans",
        "Îles marshall",
        "Îles mineures éloignées des états-unis",
        "Îles vierges des états-unis",
        "Île norfolk",
        "Îles mariannes du nord",
        "Îles salomon",
        "Îles vierges britanniques",
        "Îles turks et caïques",
        "Israël",
        "Italie",
        "Jamaïque",
        "Japon",
        "Jersey",
        "Jordanie",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Corée (république de)",
        "Corée (république populaire démocratique de)",
        "Koweït",
        "Kirghizistan",
        "République démocratique populaire lao",
        "Lettonie",
        "Liban",
        "Lesotho",
        "Libéria",
        "Libye",
        "Liechtenstein",
        "Lituanie",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaisie",
        "Maldives",
        "Mali",
        "Malte",
        "Martinique",
        "Mauritanie",
        "Maurice",
        "Mayotte",
        "Mexique",
        "Micronésie (états fédérés de)",
        "Moldova (république de)",
        "Monaco",
        "Mongolie",
        "Monténégro",
        "Montserrat",
        "Maroc",
        "Mozambique",
        "Myanmar",
        "Namibie",
        "Nauru",
        "Népal",
        "Nouvelle-calédonie",
        "Nouvelle-zélande",
        "Nicaragua",
        "Niger",
        "Nigéria",
        "Niue",
        "Norvège",
        "Oman",
        "Ouganda",
        "Pakistan",
        "Palaos",
        "Palestine, état de",
        "Panama",
        "Papouasie-nouvelle-guinée",
        "Paraguay",
        "Pays-bas",
        "Pérou",
        "Philippines",
        "Pitcairn",
        "Pologne",
        "Portugal",
        "Porto rico",
        "Qatar",
        "Macédoine du nord",
        "Roumanie",
        "Fédération de russie",
        "Rwanda",
        "Réunion",
        "Saint-barthélemy",
        "Sainte-hélène, ascension et tristan da cunha",
        "Saint-kitts-et-nevis",
        "Sainte-lucie",
        "Saint-martin (partie française)",
        "Saint-pierre-et-miquelon",
        "Saint-vincent-et-les grenadines",
        "Samoa",
        "Samoa américaines",
        "Saint-Marin",
        "Sao Tomé-et-principe",
        "Arabie saoudite",
        "Sénégal",
        "Serbie",
        "Seychelles",
        "Sierra leone",
        "Singapour",
        "Sint maarten (partie néerlandaise)",
        "Slovaquie",
        "Slovénie",
        "Somalie",
        "Géorgie du sud-et-les îles sandwich du sud",
        "Soudan du sud",
        "Sri lanka",
        "Soudan",
        "Suriname",
        "Svalbard et île jan mayen",
        "Suède",
        "Suisse",
        "République arabe syrienne",
        "Taïwan, province de chine",
        "Tadjikistan",
        "Tanzanie, république-Unie de",
        "Thaïlande",
        "Timor-leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinité-et-tobago",
        "Tunisie",
        "Turquie",
        "Turkménistan",
        "Tuvalu",
        "Ukraine",
        "Émirats arabes unis",
        "Royaume-uni de grande-bretagne et d'irlande du nord",
        "États-unis d'amérique",
        "Uruguay",
        "Ouzbékistan",
        "Vanuatu",
        "Venezuela (république bolivarienne du)",
        "Viet nam",
        "Wallis-et-futuna",
        "Sahara occidental",
        "Yémen",
        "Zambie",
        "Zimbabwe"
    ];

    useEffect(() => {
        let role = props.userToUpdate && props.userToUpdate.roles[0] ? tradRole[props.userToUpdate.roles[0].name] : 'Choisir un rôle';
        setDropDownValue(role);
        if (props.userToUpdate && props.userToUpdate.is_society) {
            setParams({ is_society: true });
        } else {
            setParams({ is_society: false, password: 'ClassAppart00!', confirm_password: 'ClassAppart00!' });
        }
    }, [props.userToUpdate]);

    function handleParamChange(event) {
        let newParams = Object.assign({}, params);
        if (event.target.type === 'checkbox') {
            newParams[event.target.name] = event.target.checked;
        } else if (event.target.name === 'role') {
            const tradRole = { tenant: 'Locataire', owner: 'Propriétaire', admin: 'Administrateur', ['super-admin']: 'Super Administrateur', trainee: 'Stagiaire / Alternant' }
            setDropDownValue(tradRole[event.target.id]);
            newParams['role'] = event.target.id;
        } else {
            newParams[event.target.name] = event.target.value;
        }
        setParams(newParams);
    }

    function handleSubmit() {
        setIsSubmit(true);
        instanceOfAxios.post('/register', params)
            .then((res) => {
                setDropDownValue('Choisir un rôle');
                dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
                props.reloadList(true);
                dispatch(ShowList());
                setIsSubmit(false);
                setErrors([]);
                setParams({ is_society: false });
            }).catch((error) => {
                dispatch(show({ message: 'Sauvegarde échoué', variant: 'danger' }));
                setErrors(error.response.data);
                setIsSubmit(false);
            });
    }

    function handleUpdate() {
        setIsSubmit(true);
        let newParams = Object.assign({}, params);
        newParams._method = 'PATCH';
        instanceOfAxios.post('/admin/account/' + props.userToUpdate.id, newParams)
            .then((res) => {
                setDropDownValue('Choisir un rôle');
                dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
                props.reloadList(true);
                dispatch(ShowList());
                setIsSubmit(false);
                setErrors([]);
            }).catch((error) => {
                dispatch(show({ message: 'Sauvegarde échoué', variant: 'danger' }));
                setErrors(error.response.data.error);
                setIsSubmit(false);
            });
    }

    function resetPassword() {
        setResetPasswordVisibility(true);
        instanceOfAxios.post('/admin/account/resetPassword', { email: props.userToUpdate.email })
            .then(res => {
                dispatch(show({ message: 'E-mail envoyé', variant: 'success' }));
                setResetPasswordVisibility(false);
            })
            .catch(err => {
                dispatch(show({ message: 'E-mail non envoyé', variant: 'danger' }));
                setResetPasswordVisibility(false);
            });
    }

    function getRoleDropDown() {
        return (<Col className='d-flex flex-column justify-content-center align-items-center align-items-md-start'>
            <Form.Label>Rôle</Form.Label>
            <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {dropDownValue}
                </Dropdown.Toggle>
                <Dropdown.Menu onClick={(e) => handleParamChange(e)}>
                    {
                        role == 'super-admin' || role == 'admin'
                            ?
                            <>
                                <Dropdown.Item name='role' id='super-admin'>Super Administrateur</Dropdown.Item>
                                <Dropdown.Item name='role' id='admin'>Administrateur</Dropdown.Item>
                            </>
                            :
                            <></>
                    }
                    <Dropdown.Item name='role' id='owner'>Propriétaire</Dropdown.Item>
                    <Dropdown.Item name='role' id='tenant'>Locataire</Dropdown.Item>
                    <Dropdown.Item name='role' id='trainee'>Stagiaire / Alternant</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Col>)
    }

    return props.className == 'slider-create-right slide-in-create-right' ?
        props.userToUpdate ?
            <div className={props.className}>
                <Row className='d-flex justify-content-center'>
                    <Col xs={12} md={6} className='d-flex justify-content-center justify-content-md-start mb-3'>
                        <Button className="btn-rounded" variant="secondary" onClick={() => {
                            dispatch(ShowList());
                            setDropDownValue('Choisir un role');
                            setErrors([]);
                        }} >
                            Retour
                        </Button>
                       
                    </Col>
                    <Col xs={12} md={6} className='d-flex justify-content-end mb-3'>
                        {
                            resetPasswordVisibility ?
                                <Spinner />
                                :
                                <Button className="btn-rounded" variant="secondary" onClick={resetPassword}>Envoyer un e-mail de réinitialisation de mot de passe</Button>
                        }
                    </Col>
                    <Col lg={6} xs={12}>
                        <h2>Compte</h2>
                        <Row className='d-flex flex-column justify-content-center align-items-center'>
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label><br />
                                    <small style={{ color: 'red' }}>{errors['email']}</small>
                                    <Form.Control type="email" name='email' placeholder="*******@******.**" defaultValue={props.userToUpdate && props.userToUpdate.email} onChange={(e) => handleParamChange(e)} />
                                </Form.Group>
                            </Col>
                            {getRoleDropDown()}
                        </Row>
                    </Col>
                </Row>
                <div className="form-check padding-row">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="is_society"
                        defaultChecked={props.userToUpdate && props.userToUpdate.is_society}
                        checked={params.is_society} onChange={(e) => handleParamChange(e)} />
                    <label className="form-check-label" htmlFor="is_society">Ce Compte est une entreprise</label>
                </div>
                {
                    params.is_society ?
                        <Row>
                            <Col lg={6} xs={12}>
                                <h2>Informations de société</h2>
                                <Form.Group>
                                    <Form.Label>Nom de la société *</Form.Label><br />
                                    <small style={{ color: 'red' }}>{errors['name']}</small>
                                    <Form.Control defaultValue={props.userToUpdate.society_information && props.userToUpdate.society_information.name} name='name' size="sm" onChange={(e) => handleParamChange(e)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>SIRET *</Form.Label><br />
                                    <small style={{ color: 'red' }}>{errors['SIRET']}</small>
                                    <Form.Control defaultValue={props.userToUpdate.society_information && props.userToUpdate.society_information.SIRET} name='SIRET' size="sm" placeholder="432*************" onChange={(e) => handleParamChange(e)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>numéro de TVA *</Form.Label><br />
                                    <small style={{ color: 'red' }}>{errors['num_TVA']}</small>
                                    <Form.Control defaultValue={props.userToUpdate.society_information && props.userToUpdate.society_information.num_TVA} name='num_TVA' size="sm" placeholder="432******" onChange={(e) => handleParamChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col lg={6} xs={12}>
                                <h2>Adresse de la société</h2>
                                <Form.Group>
                                    <Form.Label>Adresse *</Form.Label><br />
                                    <small style={{ color: 'red' }}>{errors['address']}</small>
                                    <Form.Control defaultValue={props.userToUpdate.society_information && props.userToUpdate.society_information.address ? props.userToUpdate.society_information.address.address : ''} name='address' size="sm" placeholder="34 rue des lilas" onChange={(e) => handleParamChange(e)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Deuxième ligne</Form.Label><br />
                                    <small style={{ color: 'red' }}>{errors['second_address']}</small>
                                    <Form.Control defaultValue={props.userToUpdate.society_information && props.userToUpdate.society_information.address ? props.userToUpdate.society_information.address.second_address : ''} name='second_address' size="sm" onChange={(e) => handleParamChange(e)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Troisième ligne</Form.Label><br />
                                    <small style={{ color: 'red' }}>{errors['third_address']}</small>
                                    <Form.Control defaultValue={props.userToUpdate.society_information && props.userToUpdate.society_information.address ? props.userToUpdate.society_information.address.third_address : ''} name='third_address' size="sm" onChange={(e) => handleParamChange(e)} />
                                </Form.Group>
                                <Form.Row>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>Ville *</Form.Label><br />
                                            <small style={{ color: 'red' }}>{errors['city']}</small>
                                            <Form.Control defaultValue={props.userToUpdate.society_information && props.userToUpdate.society_information.address ? props.userToUpdate.society_information.address.city : ''} name='city' size="sm" placeholder="Montpellier" onChange={(e) => handleParamChange(e)} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>Code Postale *</Form.Label><br />
                                            <small style={{ color: 'red' }}>{errors['postal_code']}</small>
                                            <Form.Control defaultValue={props.userToUpdate.society_information && props.userToUpdate.society_information.address ? props.userToUpdate.society_information.address.postal_code : ''} name='postal_code' size="sm" placeholder="34000" onChange={(e) => handleParamChange(e)} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Group>
                                    <Form.Label>Pays</Form.Label><br />
                                    <small style={{ color: 'red' }}>{errors['country']}</small>
                                    <Form.Control defaultValue={props.userToUpdate.society_information && props.userToUpdate.society_information.address ? props.userToUpdate.society_information.address.country : ''} name='country' size="sm" onChange={(e) => handleParamChange(e)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        :
                        <></>
                }
                <Row>
                    <Col xs={12} className='d-flex justify-content-center'>
                        {
                            isSubmit ?
                                <Spinner />
                                :
                                <Button className='whiteSVG' variant='secondary' onClick={handleUpdate}><FaRegSave/> Enregistrer</Button>
                        }
                    </Col>
                </Row>
            </div>
            :
            <div className={props.className}>
                <Button className="btn-rounded" variant="secondary" onClick={() => {
                    dispatch(ShowList());
                    setErrors([]);
                }} >
                    Retour
                </Button>
                <Row>
                    <Col sm={12} lg={4}>
                        <h2>Compte</h2>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label><br />
                                <small style={{ color: 'red' }}>{errors['email']}</small>
                                <Form.Control type="email" name='email' placeholder="*******@******.**" onChange={(e) => handleParamChange(e)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Mot de passe</Form.Label><br />
                                <small style={{ color: 'red' }}>{errors['password']}</small>
                                <Form.Control type={!passwordVisibility ? 'password' : 'text'} name='password' defaultValue={params.password} placeholder="mot de passe" onChange={(e) => handleParamChange(e)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Confirmez le mot de passe</Form.Label><br />
                                <small style={{ color: 'red' }}>{errors['confirm_password']}</small>
                                <Form.Control type={!passwordVisibility ? 'password' : 'text'} name='confirm_password' defaultValue={params.confirm_password} placeholder="Confirmez le mot de passe" onChange={(e) => handleParamChange(e)} />
                            </Form.Group>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" onClick={(e) => setPasswordVisibility(e.target.checked)} label='Afficher le mot de passe' />
                            </Form.Group>
                            {getRoleDropDown()}
                        </Form>
                    </Col>
                    <Col sm={12} lg={4}>
                        <h2>Contact principal</h2>
                        <Form.Group>
                            <Form.Label>Civilité *</Form.Label><br />
                            <small style={{ color: 'red' }}>{errors['civility']}</small>
                            <Form.Control name="civility" as="select" id='civility' onChange={(e) => handleParamChange(e)}>
                                <option>Selectionner</option>
                                <option value='Mme'>Mme</option>
                                <option value='Mr'>Mr</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Nom *</Form.Label><br />
                            <small style={{ color: 'red' }}>{errors['last_name']}</small>
                            <Form.Control name='last_name' size="sm" onChange={(e) => handleParamChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Prénom *</Form.Label><br />
                            <small style={{ color: 'red' }}>{errors['first_name']}</small>
                            <Form.Control name='first_name' size="sm" onChange={(e) => handleParamChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label><br />
                            <small style={{ color: 'red' }}>{errors['contact_email']}</small>
                            <Form.Control name='contact_email' size="sm" onChange={(e) => handleParamChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Téléphone *</Form.Label><br />
                            <small style={{ color: 'red' }}>{errors['phone']}</small>
                            <Form.Control name='phone' size="sm" onChange={(e) => handleParamChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Fonction</Form.Label><br />
                            <small style={{ color: 'red' }}>{errors['fonction']}</small>
                            <Form.Control name='fonction' size="sm" onChange={(e) => handleParamChange(e)} />
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={4}>
                        <h2>Adresse du contact</h2>
                        <Form.Group>
                            <Form.Label>Adresse</Form.Label><br />
                            <small style={{ color: 'red' }}>{errors['address']}</small>
                            <Form.Control name='address' size="sm" placeholder="34 rue des lilas" onChange={(e) => handleParamChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Deuxième ligne</Form.Label><br />
                            <small style={{ color: 'red' }}>{errors['second_address']}</small>
                            <Form.Control name='second_address' size="sm" onChange={(e) => handleParamChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Troisième ligne</Form.Label><br />
                            <small style={{ color: 'red' }}>{errors['third_address']}</small>
                            <Form.Control name='third_address' size="sm" onChange={(e) => handleParamChange(e)} />
                        </Form.Group>
                        <Form.Row>
                            <Col xs={12} sm={6}>
                                <Form.Group>
                                    <Form.Label>Ville *</Form.Label><br />
                                    <small style={{ color: 'red' }}>{errors['city']}</small>
                                    <Form.Control name='city' size="sm" placeholder="Montpellier" onChange={(e) => handleParamChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Form.Group>
                                    <Form.Label>Code Postale</Form.Label><br />
                                    <small style={{ color: 'red' }}>{errors['postal_code']}</small>
                                    <Form.Control name='postal_code' size="sm" placeholder="34000" onChange={(e) => handleParamChange(e)} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Group>
                            <Form.Label>Pays</Form.Label><br />
                            <small style={{ color: 'red' }}>{errors['country']}</small>

                            <Form.Control size="sm" name="country" as="select" defaultValue="France" onChange={(e) => handleParamChange(e)}>
                                {pays.map((pays, index) => (
                                    <option key={index} selected={pays}>{pays}</option>
                                ))}
                            </Form.Control>

                            {/*<Form.Control name='country' size="sm" onChange={(e) => handleParamChange(e)} />*/}
                        </Form.Group>
                    </Col>
                </Row>
                <div className="form-check padding-row">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="is_society"
                        checked={params.is_society} onChange={(e) => handleParamChange(e)} />
                    <label className="form-check-label" htmlFor="is_society">Ce Compte est une entreprise</label>
                </div>
                {
                    params.is_society &&
                    <Row>
                        <Col>
                            <h2>Informations de société</h2>
                            <Form.Group>
                                <Form.Label>Nom de la société *</Form.Label><br />
                                <small style={{ color: 'red' }}>{errors['name']}</small>
                                <Form.Control name='name' size="sm" onChange={(e) => handleParamChange(e)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>SIRET *</Form.Label><br />
                                <small style={{ color: 'red' }}>{errors['SIRET']}</small>
                                <Form.Control name='SIRET' size="sm" placeholder="432*************" onChange={(e) => handleParamChange(e)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>num_TVA *</Form.Label><br />
                                <small style={{ color: 'red' }}>{errors['num_TVA']}</small>
                                <Form.Control name='num_TVA' size="sm" placeholder="432******" onChange={(e) => handleParamChange(e)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <h2>Adresse de la société</h2>
                            <Form.Group>
                                <Form.Label>Adresse *</Form.Label><br />
                                <small style={{ color: 'red' }}>{errors['society_address']}</small>
                                <Form.Control name='society_address' size="sm" placeholder="34 rue des lilas" onChange={(e) => handleParamChange(e)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Deuxième ligne</Form.Label><br />
                                <small style={{ color: 'red' }}>{errors['society_second_address']}</small>
                                <Form.Control name='society_second_address' size="sm" onChange={(e) => handleParamChange(e)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Troisième ligne</Form.Label><br />
                                <small style={{ color: 'red' }}>{errors['society_third_address']}</small>
                                <Form.Control name='society_third_address' size="sm" onChange={(e) => handleParamChange(e)} />
                            </Form.Group>
                            <Form.Row>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Label>Ville *</Form.Label><br />
                                        <small style={{ color: 'red' }}>{errors['society_city']}</small>
                                        <Form.Control name='society_city' size="sm" placeholder="Montpellier" onChange={(e) => handleParamChange(e)} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Label>Code Postale *</Form.Label><br />
                                        <small style={{ color: 'red' }}>{errors['society_postal_code']}</small>
                                        <Form.Control name='society_postal_code' size="sm" placeholder="34000" onChange={(e) => handleParamChange(e)} />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Group>
                                <Form.Label>Pays</Form.Label><br />
                                <small style={{ color: 'red' }}>{errors['society_country']}</small>
                                <Form.Control name='society_country' size="sm" onChange={(e) => handleParamChange(e)} />
                            </Form.Group>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs={12} className='d-flex justify-content-center'>
                        {
                            isSubmit ?
                                <Spinner />
                                :
                                <Button className='whiteSVG' variant='secondary' onClick={handleSubmit}><FaRegSave/> Enregistrer</Button>
                        }
                    </Col>
                </Row>
            </div>
        :
        <></>
}
function mapStateToProps(state) {
    return {
        userToUpdate: getUserToUpdate(state)
    }
}

export default connect(mapStateToProps)(CreateOrUpdateUser);