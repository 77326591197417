import React, { useEffect } from 'react';
import instanceOfAxios from '../../app/axios/axios'
import axios from 'axios';
import { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import './showAppartment.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { IoMdSnow } from 'react-icons/io';
import { BsWifi, BsPersonFill } from 'react-icons/bs';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { MdSmokingRooms, MdPets, MdOutlineBedroomParent, MdOutlineElevator, MdOpenInNew } from 'react-icons/md';
import Book from './book/book';
import SearchResultMap from '../map/searchResultMap';
import ShareButton from './shareButton/shareButton';
import { show } from "../admin/Alerter/alerterSlice";
import { useDispatch } from "react-redux";
import moment from 'moment';
import { Carousel } from '../carousel/carousel';
import CommentsPreview from './comments/preview/commentsPreview';
const axiosAdressToLatLng = axios.create({ baseURL: "https://api-adresse.data.gouv.fr/" });

export default function ShowApartment(props) {
    const [apartment, setApartment] = useState();
    const [localisation, setLocalistion] = useState({});
    const [pending, setPending] = useState(true);
    const [collapse, setCollapse] = useState(false);
    const [principalPicture, setPrincipalPicture] = useState();
    const [allPictures, setAllPictures] = useState();
    const dispatch = useDispatch();

    // dans ce hook on récupère le logements en base de données ainsi que sa géolocalisation pour l'afficher sur la map
    useEffect(() => {
        instanceOfAxios.get('/apartment/' + props.match.params.id)
            .then((res) => {
                setApartment(res.data.apartment);
                setPending(false);
                setPrincipalPicture(res.data.apartment.principal_picture_url);
                setAllPictures(res.data.apartment.pictures_url);
                let url = apartment.address.address.replace(/\s/g, '+');
                let url2 = "search/?q=" + url;
                let url3 = url2 + "&postcode=" + apartment.address.postal_code;
                axiosAdressToLatLng.get(url3)
                    .then(res => {
                        if (res.data.features[0].properties.name.toLowerCase() != apartment.address.address.toLowerCase()) {
                            axiosAdressToLatLng.get(url2 + "&citycode=" + res.data.features[0].properties.citycode)
                                .then(res => {
                                    if (res.data.features[0]) {
                                        localisation.lng = res.data.features[0].geometry.coordinates[0];
                                        localisation.lat = res.data.features[0].geometry.coordinates[1];
                                        localisation.isOnMap = true;
                                    } else {
                                        localisation.isOnMap = false;
                                    }
                                })
                        } else if (res.data.features[0]) {
                            localisation.lng = res.data.features[0].geometry.coordinates[0];
                            localisation.lat = res.data.features[0].geometry.coordinates[1];
                            localisation.isOnMap = true;
                        } else {
                            localisation.isOnMap = false;
                        }
                        setLocalistion({ ...apartment, ...localisation });
                    })
            })
            .catch(err => {
                // window.location.href = window.location.pathname.replace('/apartment/' + props.match.params.id, '/Logements');
            });
    }, [pending]);

    document.getElementsByTagName('meta')["description"].content = apartment ? apartment.description : "chargement des metas";
    document.getElementsByTagName('meta')["short_description"].content = apartment ? apartment.short_description : "chargement des metas";
    document.getElementsByTagName('meta')["title"].content = apartment ? apartment.title : "chargement des metas";

    function nbRoom() {
        let nbRoom = JSON.parse(apartment.rooms_details).chambre;
        if (nbRoom == 0) {
            return 'Studio';
        } else {
            return nbRoom > 1 ? nbRoom + '\nChambres' : nbRoom + '\nChambre';
        }
    }

    // cette fonction est prevu pour prendre en compte le type de date présent à l'index description du logement ceci est du à la migration de l'ancienne bae de données
    function preOrDiv(description) {
        let parser = new DOMParser();
        return Object.assign([], parser.parseFromString(description, 'text/html').getElementsByTagName('p')).length > 1 ? true : false;
    }

    // cette fonction envoi une requête pour ajouter le logement dans le favori de l'utilisateur
    function addFavori() {
        instanceOfAxios.get('addFavori/' + apartment.id)
            .then(res => {
                dispatch(show({ message: res.data.message, variant: 'success' }));
            })
            .catch(err => {
                if (err.response.status == 401) {
                    dispatch(show({ message: 'Vous devez être connecté', variant: 'danger' }));
                } else {
                    dispatch(show({ message: err.response.data.message, variant: 'danger' }));
                }
            })
    }

    let content =
        <Container style={{ paddingTop: '5vh', marginBottom: '15px', maxWidth: '90vw', height: 'unset' }} className='noPadding'>
            <Row className='justifyCenter'>
                <h2>Chargement</h2>
            </Row>
            <Row className='justifyCenter'>
                <Typography className='title-skel' variant="h1"><Skeleton /></Typography>
            </Row>
            <Row className='justifyCenter'>
                <Skeleton className="carousel caroussel-skel" animation='wave' variant='rect' />
            </Row>
            <Row className='justifyCenter'>
                <Typography className='longText' variant="body1"><Skeleton /></Typography>
                <Typography className='longText' variant="body1"><Skeleton /></Typography>
                <Typography className='mediumText' variant="body1"><Skeleton /></Typography>
                <Typography className='longText' variant="body1"><Skeleton /></Typography>
                <Typography className='mediumText' variant="body1"><Skeleton /></Typography>
                <Typography className='longText' variant="body1"><Skeleton /></Typography>
                <Typography className='shortText' variant="body1"><Skeleton /></Typography>
                <Typography className='longText' variant="body1"><Skeleton /></Typography>
                <Typography className='longText' variant="body1"><Skeleton /></Typography>
                <Typography className='shortText' variant="body1"><Skeleton /></Typography>
                <Typography className='longText' variant="body1"><Skeleton /></Typography>
                <Typography className='longText' variant="body1"><Skeleton /></Typography>
            </Row>
        </Container>

    if (!pending) {
        let description = apartment.description;
        content =
            <Container style={{ paddingTop: '5vh', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
                <Col xs={12}>
                    <p style={{ color: '#B7A57E' }}><a href='/' className='golden'>Accueil</a> > {apartment.type}</p>
                </Col>
                <Col xs={12} className='headerShowAppart d-flex flex-column flex-md-row justify-content-between mb-5'>
                    <h1 className='text-center text-md-left'>
                        {apartment.title}
                    </h1>
                    <div className='d-flex justify-content-center justify-content-md-between'>
                        <ShareButton className='svgDisplay' />
                        <svg className='addFavShowApart svgDisplay' onClick={addFavori} xmlns="http://www.w3.org/2000/svg" width="52.412" height="52.412" viewBox="0 0 52.412 52.412">
                            <g id="Picto_favoris" transform="translate(-1470.588 -467)">
                                <g id="Ellipse_10" data-name="Ellipse 10" transform="translate(1470.588 467)" fill="#b7a57e" stroke="#fff" stroke-width="2">
                                    <circle cx="26.206" cy="26.206" r="26.206" stroke="none" />
                                </g>
                                <path id="Icon_feather-heart" data-name="Icon feather-heart" d="M29.807,6.794a7.836,7.836,0,0,0-11.084,0L17.213,8.3,15.7,6.794A7.837,7.837,0,1,0,4.619,17.877l1.51,1.51L17.213,30.472,28.3,19.388l1.51-1.51a7.836,7.836,0,0,0,0-11.084Z" transform="translate(1479.581 476.003)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
                            </g>
                        </svg>
                    </div>
                </Col>
                <Col xs={12} className="d-flex justify-content-center noPadding">
                    {
                        principalPicture &&
                        <Carousel principalPicture={principalPicture} all={allPictures} />
                    }
                </Col>
                <div >
                    <Row className='noPadding'>
                        <Col lg='8' xs='12'>
                            <Row>
                                <Col xs={12} className='showAppartRef noPadding'>
                                    <h2>
                                        Ref : {apartment.reference}
                                    </h2>
                                </Col>
                                <Col className='noPadding'>
                                    <div className='begin-group'>
                                        <Row className='noPadding d-flex justify-content-center'>
                                            {
                                                apartment.air_conditioning ?
                                                    <Col className="d-flex flex-column justify-content-center align-items-center">
                                                        <IoMdSnow />
                                                        Climatisation
                                                    </Col>
                                                    :
                                                    <></>
                                            }
                                            {
                                                apartment.elevator ?
                                                    <Col className="d-flex flex-column justify-content-center align-items-center">
                                                        <MdOutlineElevator />
                                                        Ascenseur
                                                    </Col>
                                                    :
                                                    <></>
                                            }
                                            {
                                                apartment.internet ?
                                                    <Col className="d-flex flex-column justify-content-center align-items-center">
                                                        <BsWifi />
                                                        Wifi
                                                    </Col>
                                                    :
                                                    <></>
                                            }
                                            <Col className="d-flex flex-column justify-content-center align-items-center text-center">
                                                <MdOutlineBedroomParent />
                                                {nbRoom()}
                                            </Col>
                                            <Col className="d-flex flex-column justify-content-center align-items-center text-center">
                                                <BsPersonFill />
                                                {apartment.nb_person} {apartment.nb_person > 1 ? 'Personnes' : 'Personne'}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs='12' className='noPadding'>
                                    <Row className="end-group noBorderPadding noPadding mb-2">
                                        <Col md={12} className="mt-3 noPadding">
                                            <h3>Séjour de minimum {apartment.min_night} nuits</h3>
                                        </Col>
                                        <Col xs={12} style={{ marginTop: '2vh' }} className='noPadding'>
                                            <h4>DESCRIPTION :</h4>
                                        </Col>
                                        <Col xs={12} className="d-flex justify-content-center m-6 noPadding" style={{ marginTop: '2vh' }}>
                                            {
                                                preOrDiv(description) ?
                                                    <div className={collapse ? 'collapsePre' : 'uncollapsePre'} dangerouslySetInnerHTML={{ __html: description }}></div>
                                                    :
                                                    <pre className={collapse ? 'collapsePre' : 'uncollapsePre'} dangerouslySetInnerHTML={{ __html: description }}></pre>
                                            }
                                        </Col>
                                        <Col xs={12} className="d-flex justify-content-center">
                                            <Button
                                                className='goldenHover'
                                                style={{ marginTop: '2vh', fontWeight: 'bold' }}
                                                onClick={() => {
                                                    setCollapse(!collapse);
                                                    if (collapse) {
                                                        window.scroll(0, window.innerHeight + window.innerHeight / 2);
                                                    }
                                                }}>{collapse ? 'En savoir -' : 'En savoir +'}</Button>
                                        </Col>
                                        {
                                            apartment.num_register &&
                                            <Col md={12} className="mt-3 noPadding">
                                                <p><strong>N° enregistrement :</strong> {apartment.num_register}</p>
                                            </Col>
                                        }
                                    </Row>
                                    <Row className="end-group noBorderPadding noPadding">
                                        <Col xs={12} className='noPadding'>
                                            <h4>À NOTER</h4>
                                        </Col>
                                        <Col xs={12} md={6} className="d-flex align-items-center justify-content-start noPadding font-weight-bold">
                                            <p><AiOutlineClockCircle /> Arrivée après {apartment.visits.arrival_hour}h</p>
                                        </Col>
                                        <Col xs={12} md={6} className="d-flex align-items-center justify-content-start noPadding font-weight-bold">
                                            <p><AiOutlineClockCircle /> Départ avant {apartment.visits.depart_hour}h</p>
                                        </Col>
                                        {
                                            apartment.smocking ?
                                                <Col xs={12} md={6} className="d-flex align-items-center justify-content-start noPadding font-weight-bold">
                                                    <p>
                                                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style={{ fill: 'currentcolor', marginRight: '5px' }}>
                                                            <path d="M24.242 19H27v2.758L24.242 19zM23 15c1.103 0 2 .897 2 2h2c0-2.206-1.794-4-4-4h-4.758l2 2H23zm6 8.758l2 2V19h-2v4.758zM18 5V3c-2.636 0-4.875 1.71-5.679 4.079l1.701 1.701C14.14 6.678 15.87 5 18 5zM4 19c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h19.758l-8-8H4zm25-2h2c0-3.629-2.429-6.701-5.746-7.677A3.97 3.97 0 0 0 26 7c0-2.206-1.794-4-4-4v2c1.103 0 2 .897 2 2s-.897 2-2 2v2h1c3.309 0 6 2.691 6 6zM3.293 3.707l26 26 1.414-1.414-26-26-1.414 1.414z" />
                                                        </svg>
                                                        Non-fumeurs
                                                    </p>
                                                </Col>
                                                :
                                                <Col xs={12} md={6} className="d-flex align-items-center justify-content-start noPadding font-weight-bold">
                                                    <p>
                                                        <MdSmokingRooms />
                                                        Logement fumeur
                                                    </p>
                                                </Col>
                                        }
                                        <Col xs={12} md={6} className="d-flex align-items-center justify-content-start noPadding font-weight-bold">
                                            {
                                                apartment.smocking ?
                                                    <p>
                                                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style={{ fill: 'currentcolor', marginRight: '5px' }}>
                                                            <path d="M3.707 2.293l26 26-1.414 1.414-26-26 1.414-1.414zm8.164 13.821l11.149 11.15a3.993 3.993 0 0 1-3.053.612l-.234-.055L16 26.82l-3.733 1.001a4 4 0 0 1-2.941-.347l-.204-.118-.197-.131a4 4 0 0 1-1.08-5.398l.119-.177 3.907-5.536zM27 12a4 4 0 1 1 0 8 4 4 0 0 1 0-8zM5 12a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm16-8a4 4 0 1 1 0 8 4 4 0 0 1 0-8zM11 4a4 4 0 0 1 3.381 6.138l-5.52-5.519A3.981 3.981 0 0 1 11 4z" />
                                                        </svg>
                                                        Pas d'animaux
                                                    </p>
                                                    :
                                                    <p>
                                                        <MdPets />
                                                        Animaux acceptés
                                                    </p>
                                            }
                                        </Col>
                                        <Col xs={12} md={6} className="d-flex align-items-center justify-content-start noPadding font-weight-bold">
                                            <p>
                                                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style={{ fill: 'currentcolor', marginRight: '5px' }}>
                                                    <path d="M5.12 9.363l13.882 13.88c-.494.349-1.01.694-1.549 1.035L19.65 27.2a1.125 1.125 0 0 1-.433 1.7l-.111.043-.157.039-.04.006-.158.013H17v3h-2v-3h-1.75a1.125 1.125 0 0 1-1.117-1.264l.025-.131.067-.189.021-.045.103-.17 2.14-2.862C8.212 20.918 5 16.457 5 11c0-.556.041-1.103.12-1.637zm-1.413-7.07l26 26-1.414 1.414-26-26 1.414-1.414zM16 0c5.909 0 11 5.275 11 11 0 2.915-1.167 5.676-3.472 8.286L7.853 3.609A10.972 10.972 0 0 1 16 0zm6 7a2 2 0 1 0 0 4 2 2 0 0 0 0-4z">
                                                    </path>
                                                </svg>
                                                Soirées et fêtes interdites</p>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4 end-group noBorderPadding noPadding text-center">
                                        <Col xs={12} className='noPadding text-left mb-4'>
                                            <h4>DOCUMENTS</h4>
                                        </Col>
                                        {
                                            apartment.inventory_url &&
                                            <Col xs={12} sm={6} className='mt-2'>
                                                <a target='_blank' className='btnDoc' href={apartment.inventory_url}>
                                                    <Button className='btn goldenHover'>Inventaire <MdOpenInNew /></Button>
                                                </a>
                                            </Col>
                                        }
                                        {
                                            apartment.place_state_url &&
                                            <Col xs={12} sm={6} className='mt-2'>
                                                <a target='_blank' className='btnDoc' href={apartment.place_state_url}>
                                                    <Button className='btn goldenHover'>Etat des lieux <MdOpenInNew /></Button>
                                                </a>
                                            </Col>
                                        }
                                    </Row>
                                    <Row className="end-group noBorderPadding noPadding">
                                        <CommentsPreview apartment={apartment}/>
                                    </Row>
                                    <Row className="end-group noBorderPadding noPadding">
                                        <Col xs={12} className='noPadding'>
                                            <h4>LE QUARTIER</h4>
                                        </Col>
                                        <Col xs={12} className='noPadding' style={{ width: '100%', height: '400px' }}>
                                            <SearchResultMap markers={[localisation]} class='search-map-unique' />
                                        </Col>
                                    </Row>
                                    <Row className="end-group noBorderPadding noPadding">
                                        <Col md={12} className="noPadding">
                                            <div className="btn-Content text-center">
                                                <h4>NOUS CONTACTER</h4>
                                                <Row style={{ width: '100%' }} className='d-flex justify-content-around'>
                                                    <Col xs={12} sm={4} className="btn-secondary btn-contact-result">
                                                        <a href='/Contact' style={{ textDecoration: 'none', color: 'white' }}>
                                                            <Col xs={12} variant='secondary' className='noPadding'>Nous écrire</Col>
                                                        </a>
                                                    </Col>
                                                    <Col xs={12} sm={4} className=" btn-secondary btn-contact-result" variant='secondary'>
                                                        <a href='tel:0963698742' style={{ textDecoration: 'none', color: 'white' }}>
                                                            <Col xs={12} variant='secondary' className='noPadding'>09 63 69 87 42</Col>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex f-direction bottomInformation mb-5 mt-5 d-flex justify-content-center">
                                        <Col xs={6} xl={3} className='noPadding mb-2'>
                                            <div className="d-flex flex-column align-items-center justify-content-center bottomInformationCard mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="80.962" height="83.671" viewBox="0 0 80.962 83.671">
                                                    <path id="Pictocovid" d="M203.335,312.247V292.466a2.919,2.919,0,0,1,5.819,0v19.781a.506.506,0,0,0,.519.48.5.5,0,0,0,.519-.48v-16.64a2.923,2.923,0,0,1,5.827,0v18.366a.5.5,0,0,0,.519.48.506.506,0,0,0,.52-.48V302.848a2.918,2.918,0,0,1,5.818,0v16.76a46.438,46.438,0,0,1,9.495-2.869c-.519-7.976-2.693-16.256-8.672-21.507-4.731-4.156-7.936-11.309-11.149-19.885-10.03,5.347-9.215,8.432-18.318,15.073-5.722,4.18-14.234,5.011-21.619,7.105.767,7.057,5.907,13.939,6.506,20.46,1.239,13.483,4.843,16.736,11.189,21.795a42.651,42.651,0,0,1,8.288-4.452,25.4,25.4,0,0,0-2.829-5.091c-3.013-4.42-5.163-9.567-8.792-14.226-2.382-3.061,2.206-5.826,5.459-2.653l3.045,2.965c.544.528,1,.2,1-.432V295.607a2.919,2.919,0,0,1,5.819,0v16.64a.521.521,0,0,0,1.039,0Zm-40.217-12.484a2.666,2.666,0,0,1-1.774-1.774l-1.071-3.98-1.071,3.98a2.693,2.693,0,0,1-1.782,1.774l-3.98,1.079,3.98,1.071a2.693,2.693,0,0,1,1.782,1.774l1.071,3.988,1.071-3.988a2.666,2.666,0,0,1,1.774-1.774l3.988-1.071Zm8.656-12.892a3.5,3.5,0,0,1,2.318-2.318l5.2-1.4-5.2-1.4a3.493,3.493,0,0,1-2.318-2.31l-1.4-5.195-1.4,5.195a3.465,3.465,0,0,1-2.31,2.31l-5.195,1.4,5.195,1.4a3.476,3.476,0,0,1,2.31,2.318l1.4,5.195Zm51.1,34.854v3.045a18,18,0,0,1-2.318,8.951c-1.343,2.677-.871,5.027-.559,7.928l1.558,14.642h-24.5l2.222-15.609a9.781,9.781,0,0,0-.128-3.509q-1.259.563-2.494,1.151a28.991,28.991,0,0,0-5.187,2.973l-1.191.919-1.175-.935a43.07,43.07,0,0,1-5.763-5.227c-4.38-5.035-5.547-11.437-6.138-17.895-.336-3.637-2.31-7.681-3.717-11.038a34.242,34.242,0,0,1-2.781-9.391l-.176-1.607,1.559-.447c2.246-.631,4.524-1.143,6.8-1.662,4.66-1.063,10.286-2.286,14.2-5.147a47.351,47.351,0,0,0,5.155-4.292c1.215-1.175,2.342-2.406,3.509-3.621a37.162,37.162,0,0,1,9.895-7.305l1.926-1.031.767,2.054c2.294,6.138,5.594,14.706,10.613,19.118,6.49,5.691,8.784,14.514,9.327,22.818l.112,1.662-1.63.344a43.078,43.078,0,0,0-9.895,3.109Z" transform="translate(-153.439 -272.621)" fill="#b7a57e" fill-rule="evenodd" />
                                                </svg>
                                                <p>NETTOYAGE RENFORCÉ</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} xl={3} className='noPadding mb-2'>
                                            <div className="d-flex flex-column align-items-center justify-content-center bottomInformationCard mr-xl-2 ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 147.619 94.898">
                                                    <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people" d="M103.4,49.166a20.012,20.012,0,0,0,20.13-19.77,20.133,20.133,0,0,0-40.26,0A20.012,20.012,0,0,0,103.4,49.166Zm-53.679,0A20.012,20.012,0,0,0,69.849,29.4a20.133,20.133,0,0,0-40.26,0A20.012,20.012,0,0,0,49.72,49.166Zm0,14.5c-15.77,0-46.97,7.578-46.97,23.065v17.793h94.9V86.73C97.648,71.242,65.488,63.664,49.72,63.664ZM103.4,67.29a35.809,35.809,0,0,0-5.751.329c7.719,5.6,10.544,9.226,10.544,19.111v17.793h42.177V86.73C150.369,71.242,119.169,67.29,103.4,67.29Z" transform="translate(-2.75 -9.625)" fill="#b7a57e" />
                                                </svg>
                                                <p>PLUS DE 8000 RESERVATIONS EN {moment("20130101", "YYYYMMDD").fromNow().toUpperCase().substr(6)}</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} xl={3} className='noPadding mb-2'>
                                            <div className="d-flex flex-column align-items-center justify-content-center bottomInformationCard mr-2 ml-xl-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.794 86.901">
                                                    <path id="Icon_awesome-star" data-name="Icon awesome-star" d="M41.968,3.022,30.886,25.491,6.091,29.106a5.433,5.433,0,0,0-3,9.266l17.938,17.48L16.783,80.545a5.427,5.427,0,0,0,7.874,5.719L46.838,74.6,69.019,86.264a5.432,5.432,0,0,0,7.874-5.719L72.651,55.852l17.938-17.48a5.433,5.433,0,0,0-3-9.266L62.791,25.491,51.709,3.022a5.435,5.435,0,0,0-9.741,0Z" transform="translate(-1.441 0.001)" fill="#b7a57e" />
                                                </svg>
                                                <p>NOTE DE <br /> {apartment.note ? apartment.note : '4.6'} / 5</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} xl={3} className='noPadding mb-2'>
                                            <div className="d-flex flex-column align-items-center justify-content-center bottomInformationCard ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="86.635" height="28.877" viewBox="0 0 86.635 28.877">
                                                    <path id="Icon_awesome-hand-holding" data-name="Icon awesome-hand-holding" d="M85.021,23.718a4.979,4.979,0,0,0-6.407,0l-13.9,11.115A9.575,9.575,0,0,1,58.7,36.938H40.909a2.406,2.406,0,1,1,0-4.813H52.685a5.007,5.007,0,0,0,5.008-4A4.818,4.818,0,0,0,52.941,22.5H28.877a17.7,17.7,0,0,0-11.145,3.956l-6.994,5.67H2.406A2.413,2.413,0,0,0,0,34.532V48.97a2.413,2.413,0,0,0,2.406,2.406H56.069a9.637,9.637,0,0,0,6.016-2.106l22.74-18.2A4.81,4.81,0,0,0,85.021,23.718Z" transform="translate(0 -22.5)" fill="#b7a57e" />
                                                </svg>
                                                <p>OFFRE TOUT INCLUS</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg='4' xs='12'>
                            <Book apartment={apartment} changeAppart={setApartment} />
                        </Col>
                    </Row>
                </div>
            </Container>
    }
    return content;
}